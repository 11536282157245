import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import { ContentGrid } from "./global"

const FooterWrapper = styled.footer`
  margin: auto 0 0;
  padding: 1.5rem 0 0;
`

const FooterInnerWrapper = styled.div`
  padding: 1.5rem 0 0;
  grid-column: content-start / content-end;
  border-top: 1px solid rgba(255, 255, 255, 0.6);

  /* delete this line later */
  padding: 0;
`

const FooterLink = styled.div`
  /* delete this line later */
  display: none;

  .footer-link {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
  }
`

const CopyrightWrapper = styled.div`
  margin: 1rem 0;
  text-align: center;
  font-size: 0.85rem;
  letter-spacing: 0.03em;
  opacity: 0.6;
`

const date = new Date()
const year = date.getFullYear()

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allNavigations(uid: "footer_navigation") {
          edges {
            node {
              _meta {
                uid
              }
              navigation_links {
                label
                link {
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Post {
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <FooterWrapper>
      <ContentGrid>
        <FooterInnerWrapper>
          {data.prismic.allNavigations.edges[0].node.navigation_links.map(
            navLink => (
              <FooterLink key={navLink.link._meta?.uid}>
                <Link
                  to={
                    navLink.link._meta.uid === "_homepage"
                      ? "/"
                      : "/" + navLink.link._meta.uid
                  }
                  className="footer-link"
                >
                  {navLink.label}
                </Link>
              </FooterLink>
            )
          )}
          <CopyrightWrapper>
            <div>Copyright © {year} FiveObservables</div>
          </CopyrightWrapper>
        </FooterInnerWrapper>
      </ContentGrid>
    </FooterWrapper>
  )
}

export default Footer
