import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const BrandOuterWrapper = styled.div`
  height: 100%;
  display: inline-block;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 33px;
  text-transform: uppercase;

  .brand-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
  }

  a {
    padding: 0.8em 0 0.7em;
    line-height: 0;
    color: #fff;
    text-decoration: none;
    letter-spacing: calc(0.1rem - 0.05em);

    span {
      color: ${props => props.theme.colors.teal};
    }
  }
`

const Brand = ({ sizeMult }) => {
  const baseSize = 33

  return (
    <BrandOuterWrapper>
      <div
        className="brand-wrapper"
        style={{ fontSize: `${baseSize * sizeMult}px` }}
      >
        <Link to="/">
          Five<span>Observables</span>
        </Link>
      </div>
    </BrandOuterWrapper>
  )
}

export default Brand
