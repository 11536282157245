import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const ThumbnailOuterWrapper = styled.div`
  a {
    color: #fff;
    text-decoration: none;

    .post-thumb-title {
      position: relative;
    }

    .post-thumb-title:before {
      content: "";
      height: 2px;
      width: 100%;
      position: absolute;
      /* bottom: 0; */
      left: 0;
      background-image: linear-gradient(
        to right,
        ${props => props.theme.colors.teal},
        ${props => props.theme.colors.teal},
        ${props => props.theme.colors.bg},
        ${props => props.theme.colors.bg}
      );
      background-size: 280%;
      background-position-x: 100%;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: transform 0.15s ease-out, background-position 0.3s ease-out;
    }

    &:hover .post-thumb-title:before,
    &:focus .post-thumb-title:before {
      background-position: 0;
      transform: scaleX(1);
    }
  }
`

const ThumbnailInnerWrapper = styled.div`
  height: 0;
  width: 100%;
  padding-bottom: 60%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
`

const ThumbnailTitle = styled.div`
  padding: 0.4em 0 0;
  font-family: ${props => props.theme.fonts.heading};
  font-size: 1.6rem;
  line-height: 1.3;
  text-transform: uppercase;
  text-decoration: none;
`

const PostThumb = ({ title, image, url }) => {
  return (
    <ThumbnailOuterWrapper>
      <Link to={`/${url}`}>
        <ThumbnailInnerWrapper>
          <img src={image ? image.url : ""} alt={image ? image.alt : ""} />
        </ThumbnailInnerWrapper>
        <div className="post-thumb-title">
          <ThumbnailTitle>{title}</ThumbnailTitle>
        </div>
      </Link>
    </ThumbnailOuterWrapper>
  )
}

export default PostThumb
