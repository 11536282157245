const theme = {
  colors: {
    darkGray: "#222",
    teal: "#00ffc2",
    white: "#fff",
  },
  fonts: {
    heading: "Unica One, sans-serif",
    body: "PT Sans, sans-serif",
  },
  measurements: {
    header: {
      mobileHeaderHeight: "64px",
    },
  },
  sizes: {
    small: "576px",
    medium: "768px",
    large: "990px",
    xlarge: "1220px",
    xxlarge: "1460px",
    xxxlarge: "1750px",
  },
}

theme.breakpoints = {
  small: `(min-width: ${theme.sizes.small})`,
  medium: `(min-width: ${theme.sizes.medium})`,
  large: `(min-width: ${theme.sizes.large})`,
  xlarge: `(min-width: ${theme.sizes.xlarge})`,
  xxlarge: `(min-width: ${theme.sizes.xxlarge})`,
  xxxlarge: `(min-width: ${theme.sizes.xxxlarge})`,
}

theme.colors.bg = theme.colors.darkGray
theme.colors.text = theme.colors.white

export default theme
