import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Brand from "./brand"

const DesktopNavbarResponsive = styled.div`
  display: none;

  @media ${props => props.theme.breakpoints.medium} {
    display: block;
  }
`

const DesktopBrandWrapper = styled.div`
  /* margin: 0 auto; */
  margin: 0.7rem 0 0;
  text-align: center;
`

const DesktopNavRow = styled.div`
  .desktop-nav-list {
    margin: -0.5rem 0 1.5rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
`

const DesktopNavItem = styled.div`
  margin: 0 1rem;

  .desktop-nav-link {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 1.5rem;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none;
  }
`

const DesktopNavbar = ({ navLinks }) => {
  return (
    <DesktopNavbarResponsive>
      <DesktopBrandWrapper>
        <Brand sizeMult="2" />
      </DesktopBrandWrapper>
      <DesktopNavRow>
        <ul className="desktop-nav-list blank-list">
          {navLinks.map(navLink => (
            <DesktopNavItem key={navLink.link._meta.uid}>
              <Link
                className="desktop-nav-link"
                to={
                  navLink.link._meta.uid === "_homepage"
                    ? "/"
                    : "/" + navLink.link._meta.uid
                }
              >
                {navLink.label}
              </Link>
            </DesktopNavItem>
          ))}
        </ul>
      </DesktopNavRow>
    </DesktopNavbarResponsive>
  )
}

export default DesktopNavbar
