import * as React from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import Navbar from "./navbar"

const HeaderWrapper = styled.header`
  position: relative;
  z-index: 1000;
`

const Header = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allNavigations(uid: "header_navigation") {
          edges {
            node {
              _meta {
                uid
              }
              navigation_links {
                label
                link {
                  ... on PRISMIC_Page {
                    _meta {
                      uid
                    }
                  }
                  ... on PRISMIC_Post {
                    page_title
                    _meta {
                      uid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <HeaderWrapper>
      <Navbar
        navLinks={data.prismic.allNavigations.edges[0].node.navigation_links}
      />
    </HeaderWrapper>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
