import * as React from "react"
import PropTypes from "prop-types"
import styled, { ThemeProvider } from "styled-components"

import { ContentGrid } from "./global"
import theme from "../theme/theme"
import Header from "./header"
import Footer from "./footer"
import PostThumbs from "../components/postThumbs"

import "../styles/normalize.css"
import "../styles/main.css"

const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`

const PostThumbsWrapper = styled.div`
  /* change this to wide-start when there are more thumbs */
  grid-column: content-start / content-end;

  @media ${props => props.theme.breakpoints.large} {
    /* change this to wide-start when there are more thumbs */
    grid-column: content-start / content-end;
  }
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <PageWrapper>
        <Header />
        <main>{children}</main>
        <ContentGrid>
          <PostThumbsWrapper>
            <PostThumbs />
          </PostThumbsWrapper>
        </ContentGrid>
        <Footer />
      </PageWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
