import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Brand from "./brand"

const MobileNavbarResponsive = styled.div`
  display: block;

  @media ${props => props.theme.breakpoints.medium} {
    display: none;
  }
`

const MobileNavbarRow = styled.div`
  height: ${props => props.theme.measurements.header.mobileHeaderHeight};
  padding: 0 1.3rem;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: stretch;
  align-items: center;
  box-sizing: border-box;
  z-index: 1000;

  .mobile-nav-top-border {
    height: ${props => props.theme.measurements.header.mobileHeaderHeight};
    /* subtract width of padding and hamburger icon */
    width: calc(100% - 1.3rem - 45px);
    position: absolute;
    left: calc(50% - 50vw);
    border-right: 1px solid transparent;
    border-bottom: 1px solid transparent;
    box-sizing: border-box;
    transition: border 0.5s ease;
    pointer-events: none;
  }

  .mobile-nav-top-border-expanded {
    border-color: white;
  }
`

const MobileBrandWrapper = styled.div`
  flex-grow: 1;
  align-self: stretch;
`

const Hamburger = styled.div`
  width: 26px;
  padding: 0 0 0 1rem;

  .hamburger-middle,
  .hamburger-wrapper:before,
  .hamburger-wrapper:after {
    height: 3px;
    width: 100%;
    background: #fff;
  }

  .hamburger-middle {
    margin: 6px 0;
    /* transition: opacity 0.2s linear, transform 0.2s ease-out; */
  }

  .hamburger-wrapper:before,
  .hamburger-wrapper:after {
    content: "";
    position: relative;
    display: block;
    transform-origin: 100% 50%;
    /* transition: transform 0.2s ease-out; */
  }

  .hamburger-wrapper-expanded {
    &.hamburger-wrapper:before {
      transform: translateX(-2px) rotate(-45deg) !important;
    }

    &.hamburger-wrapper:after {
      transform: translateX(-2px) rotate(45deg) !important;
    }

    .hamburger-middle {
      transform: scaleX(0);
      opacity: 0;
    }
  }
`

const MobileNavbar = ({ toggleMenu, navLinks, isExpanded }) => {
  return (
    <MobileNavbarResponsive>
      <MobileNavbarRow>
        <div
          className={
            "mobile-nav-top-border" +
            (isExpanded ? " mobile-nav-top-border-expanded" : "")
          }
        ></div>
        <MobileBrandWrapper>
          <Brand fontSize="33px" />
        </MobileBrandWrapper>
        <Hamburger onClick={toggleMenu}>
          <div
            className={
              "hamburger-wrapper" +
              (isExpanded ? " hamburger-wrapper-expanded" : "")
            }
          >
            <div className="hamburger-middle"></div>
          </div>
        </Hamburger>
      </MobileNavbarRow>
    </MobileNavbarResponsive>
  )
}

export default MobileNavbar
