import React, { useState } from "react"

import MobileNavMenu from "./mobileNavMenu"
import MobileNavbar from "./mobileNavbar"
import DesktopNavbar from "./desktopNavbar"

const Navbar = ({ navLinks }) => {
  const [isExpanded, setExpanded] = useState(false)
  const toggleMenu = () => {
    setExpanded(!isExpanded)
  }

  return (
    <nav>
      <MobileNavMenu navLinks={navLinks} isExpanded={isExpanded} />
      <MobileNavbar
        toggleMenu={toggleMenu}
        navLinks={navLinks}
        isExpanded={isExpanded}
      />
      <DesktopNavbar navLinks={navLinks} />
    </nav>
  )
}

export default Navbar
