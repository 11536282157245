import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const MobileNavMenuOuterWrapper = styled.div`
  width: 100vw;
  /* margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%); */
  position: relative;
  z-index: -1;

  @media ${props => props.theme.breakpoints.medium} {
    display: none;
  }
`

const MobileNavMenuMiddleWrapper = styled.div`
  width: 100%;

  position: relative;
  z-index: -1;

  .mobile-nav-menu-wrapper {
    width: 100vw;
    padding: calc(
        0.7rem + ${props => props.theme.measurements.header.mobileHeaderHeight}
      )
      1.3rem 0.7rem;

    position: absolute;
    left: 0;
    background-color: transparent;
    text-align: right;
    color: transparent;
    border-bottom: 1px solid transparent;
    box-sizing: border-box;
    /* opacity: 0; */
    /* transform: scaleY(0.85); */
    transform-origin: 50% 0;
    transition: color 0.5s ease-out, background-color 0.5s ease-out,
      border 0.5s ease-out;
    /* transition: opacity 0.5s ease, transform 0.5s ease-out; */
    pointer-events: none;

    &:after {
      content: "";
      height: 70vh;
      width: 100%;
      position: absolute;
      top: calc(100% + 1px);
      left: 0;
      /* background-image: linear-gradient(
        to bottom,
        rgba(34, 34, 34, 0.9),
        rgba(34, 34, 34, 0)
      ); */
    }

    li {
      margin: 0.7em 0;
      transition: margin 0.5s ease-out;
    }
  }

  .mobile-nav-menu-wrapper-expanded {
    top: 0;
    left: 0;
    color: #fff;
    background-color: ${props => props.theme.colors.bg};
    border-color: #fff;
    /* opacity: 1; */
    transform: scaleY(1);
    pointer-events: initial;
    z-index: 1;
  }
`

const MobileNavItem = styled.li`
  .mobile-nav-link {
    font-family: ${props => props.theme.fonts.heading};
    font-size: 1.5rem;
    text-transform: uppercase;
    text-decoration: none;
    /* color: #fff; */
    color: inherit;
  }
`

const MobileNavMenu = ({ navLinks, isExpanded }) => {
  return (
    <MobileNavMenuOuterWrapper>
      <MobileNavMenuMiddleWrapper>
        <div
          className={
            "mobile-nav-menu-wrapper" +
            (isExpanded ? " mobile-nav-menu-wrapper-expanded" : "")
          }
        >
          <ul className="blank-list">
            {navLinks.map(navLink => (
              <MobileNavItem key={navLink.link._meta.uid}>
                <Link
                  className="mobile-nav-link"
                  to={
                    navLink.link._meta.uid === "_homepage"
                      ? "/"
                      : "/" + navLink.link._meta.uid
                  }
                >
                  {navLink.label}
                </Link>
              </MobileNavItem>
            ))}
          </ul>
        </div>
      </MobileNavMenuMiddleWrapper>
    </MobileNavMenuOuterWrapper>
  )
}

export default MobileNavMenu
