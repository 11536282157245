import styled from "styled-components"

const Container = styled.div`
  max-width: 100%;
  margin: 0 auto;
  /* padding: 0 1.3rem; */
  box-sizing: border-box;

  /* @media ${props => props.theme.breakpoints.small} {
    padding: 0 2.6rem;
  }

  @media ${props => props.theme.breakpoints.medium} {
    max-width: ${props => props.theme.sizes.medium};
  }

  @media ${props => props.theme.breakpoints.large} {
    max-width: 820px;
  }

  @media ${props => props.theme.breakpoints.xlarge} {
    max-width: 880px;
  }

  @media ${props => props.theme.breakpoints.xxxlarge} {
    max-width: 980px;
  } */
`

// prettier-ignore
const ContentGrid = styled.div`
  --viewport-padding: 1.3rem;
  --cancel-padding: 0;
  --content-width: 1fr;
  /* fallback for non-grid browsers */
  padding: 0 1.3rem;
  /* zero padding; handle "padding" with CSS grid */
  padding: var(--cancel-padding);
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: [full-start] var(--viewport-padding) [padding-start wide-start content-start] var(--content-width) [content-end wide-end] var(--viewport-padding) [full-end];

  & > * {
    grid-column: content-start / content-end;
  }

  @media ${props => props.theme.breakpoints.medium} {
    grid-template-columns: [full-start] var(--viewport-padding) [padding-start wide-start] 60px [content-start] var(--content-width) [content-end] 60px [wide-end] var(--viewport-padding) [full-end];
  }

  @media ${props => props.theme.breakpoints.large} {
    --content-width: 810px;
    grid-template-columns: [full-start] var(--viewport-padding) [padding-start] 1fr [wide-start] 70px [content-start] var(--content-width) [content-end] 70px [wide-end] 1fr [padding-end] var(--viewport-padding) [full-end];
  }

  @media ${props => props.theme.breakpoints.xlarge} {
    --content-width: 840px;
    grid-template-columns: [full-start] var(--viewport-padding) [padding-start] 1fr [wide-start] 80px [content-start] var(--content-width) [content-end] 80px [wide-end] 1fr [padding-end] var(--viewport-padding) [full-end];
  }

  @media ${props => props.theme.breakpoints.xlarge} {
    --content-width: 860px;
  }

  @media ${props => props.theme.breakpoints.xlarge} {
    --content-width: 880px;
  }
`

export { ContentGrid }
