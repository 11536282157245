import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

import PostThumb from "./postThumb"

const PostGrid = styled.div`
  max-width: 100%;
  margin: 2rem auto;
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(min(${props => props.boxWidth || "300px"}, 100%), 1fr)
  );
  grid-gap: ${props => props.gridGap || 0};

  @media ${props => props.theme.breakpoints.small} {
    max-width: ${props => props.mobileMaxWidth || "400px"};
  }

  @media ${props => props.theme.breakpoints.medium} {
    max-width: initial;
  }
`

const PostThumbs = () => {
  const data = useStaticQuery(graphql`
    query {
      prismic {
        allPosts {
          edges {
            node {
              featured_image
              page_title
              short_title
              _meta {
                uid
              }
            }
          }
        }
      }
    }
  `)

  console.log(data)

  return (
    <PostGrid boxWidth="280px" gridGap="30px 25px" mobileMaxWidth="400px">
      {data.prismic.allPosts.edges
        .filter(({ node }) => !!node.featured_image)
        .map(({ node }) => (
          <PostThumb
            key={node._meta.uid}
            title={node.short_title || node.page_title[0].text}
            image={node.featured_image}
            url={node._meta.uid}
          />
        ))}
    </PostGrid>
  )
}

export default PostThumbs
